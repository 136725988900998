const privateLibrary = {
  'pages.material.material': '物料',
  'pages.material.material.name': '名称',
  'pages.material.material.onlyName': '名称',
  'pages.material.material.materialNo': '编码',
  'pages.material.material.orderNo': '订货号',
  'pages.material.material.categoryName': '品类',
  'pages.material.material.specification': '规格',
  'pages.material.material.modelName': '模型名称',
  'pages.material.material.customizedNo': '定制备注',
  'pages.material.material.baseMaterialNo': '基准物料编码',
  'pages.material.material.materialAttribute': 'MRP类型',
  'pages.material.material.materialAttribute1': '自制',
  'pages.material.material.materialAttribute2': '外采',
  'pages.material.material.materialAttribute3': '外协',
  'pages.material.material.materialAttribute4': '外包',
  'pages.material.material.materialType': '物料类型',
  'pages.material.material.materialType1': '原材料',
  'pages.material.material.materialType2': '半成品',
  'pages.material.material.materialType3': '成品',
  'pages.material.material.materialType4': '直接辅料',
  'pages.material.material.materialType5': '间接辅料',
  'pages.material.material.materialType6': '备件类',
  'pages.material.material.materialType7': '办公类',
  'pages.material.material.materialType8': '服务类',
  'pages.material.material.materialUnit': '基本单位',
  'pages.material.material.unit': '单位',
  'pages.material.material.approveStatus': '审核状态',
  'pages.material.material.materialDesc': '描述',
  'pages.material.material.waste': '损耗',
  'pages.material.material.brand': '品牌',
  'pages.material.material.brandName': '品牌',
  'pages.material.material.seriesName': '系列',
  'pages.material.material.failCause': '失败原因',
  'pages.material.material.approveStatus0': '草稿',
  'pages.material.material.approveStatus1': '待审核',
  'pages.material.material.approveStatus2': '审核通过',
  'pages.material.material.approveStatus3': '审核驳回',
  'pages.material.material.approveStatus6': '重新审核',
  'pages.material.material.basicInfo': '基础信息',
  'pages.material.material.sizeUnit': '尺寸单位',
  'pages.material.material.controlScope': '控制范围',
  'pages.material.material.purchased': '允许采购',
  'pages.material.material.selled': '允许销售',
  'pages.material.material.stocked': '允许库存',
  'pages.material.material.producted': '允许生产',
  'pages.material.material.outsourced': '允许委外',
  'pages.material.material.specAttribute': '规格属性',
  'pages.material.material.saveAndConfigBOMDoc': '保存并配置',
  'pages.material.material.onlySaveBasicInfo': '仅保存基础信息',
  'pages.material.material.deleteMsg': '此操作会删除{name}{field}，将导致该{field}不可用',
  'pages.material.material.process': '工艺',
  'pages.material.material.createMaterial': '新建物料',
  'pages.material.material.deleteMaterial': '删除物料',
  'pages.material.material.copyAndAdd': '复制新增',
  'pages.material.material.editMaterial': '编辑物料',
  'pages.material.material.applySeries': '适用系列',
  'pages.material.material.materialSourceName': '物料来源',
  'pages.material.material.materialSourceName0': '手动创建',
  'pages.material.material.materialSourceName1': '自动创建',
  'pages.material.material.materialSourceName2': '模板导入',
  'pages.material.material.materialSourceName3': '公库同步',
  'pages.material.material.sizeAndWeight': '尺寸与重量',
  'pages.material.material.bomStructure': 'BOM',
  'pages.material.material.updateBomV': '添加BOM版本',
  'pages.material.material.materialDoc': '物料文档',
  'pages.material.material.processRoute': '工艺路线',
  'pages.material.material.uploadDoc': '上传文档',
  'pages.material.material.selectDoc': '选择文档',
  'pages.material.material.selectRoute': '选择工艺路线',
  'pages.material.material.setEnable': '设为启用',
  'pages.material.material.confirmAdd': '确认添加',
  'pages.material.material.mm': '毫米',
  'pages.material.material.cm': '厘米',
  'pages.material.material.dm': '分米',
  'pages.material.material.m': '米',
  'pages.material.material.weightUnit': '重量单位',
  'pages.material.material.mg': '毫克',
  'pages.material.material.g': '克',
  'pages.material.material.kg': '千克',
  'pages.material.material.t': '吨',
  'pages.material.material.length': '长度',
  'pages.material.material.width': '宽度',
  'pages.material.material.height': '高度',
  'pages.material.material.perimeter': '周长',
  'pages.material.material.weight': '重量',
  'pages.material.material.createBom': '新建BOM',
  'pages.material.material.createRoute': '新建路线',
  'pages.material.material.serialNumber': '编号',
  'pages.material.material.materialQuantity': '数量',
  'pages.material.material.isInstallAccessories': '是否安装附件',
  'pages.material.material.materialIdList': '替换物料',
  'pages.material.material.docName': '文件名称',
  'pages.material.material.documentNo': '文档编号',
  'pages.material.material.docType': '文档类型',
  'pages.material.material.docVersion': '文档版本',
  'pages.material.material.documentAssociatedSourceName': '关联来源',
  'pages.material.material.docSize': '文件大小',
  'pages.material.material.format': '文件格式',
  'pages.material.material.description': '文档用途',
  'pages.material.material.stateName': '状态',
  'pages.material.material.materialShortNo': '订货代码',
  'pages.material.material.bomVersion': '版本',
  'pages.material.material.positionName': '位置',
  'pages.material.material.routeProcessList': '关联工艺',
  'pages.material.material.processEquipments': '设备类型',
  'pages.material.material.equipmentDuration': '设备时长',
  'pages.material.material.processWorkTypes': '工种',
  'pages.material.material.workTypeDuration': '工种时长',
  'pages.material.material.processNo': '关联工序编号',
  'pages.material.material.setDefault': '设为默认',
  'pages.material.material.onlyBrandName': '品牌',
  'pages.material.material.more': '更多',
  'pages.material.material.remark': '备注',
  'pages.material.material.opinion': '审核意见',
  'pages.material.material.isStandard': '是否标准品',
  'pages.material.material.isStandard0': '标准品',
  'pages.material.material.isStandard1': '定制品',
  'pages.material.material.state': '启用状态',
  'pages.material.material.state0': '未启用',
  'pages.material.material.state1': '已启用',
  'pages.material.material.state2': '已停用',
  'pages.material.material.approveStateName': '审核状态',
  'pages.material.material.feedingType': '投料方式',

  'pages.material.material.feedingType10': '直接领料',
  'pages.material.material.feedingType20': '直接倒冲',
  'pages.material.material.feedingType30': '调拨领料',
  'pages.material.material.feedingType40': '调拨倒冲',
  'pages.material.material.feedingType50': '不发料',
  'pages.material.material.structureId': '产品结构',
  'pages.material.material.creatorName': '创建人',
  'pages.material.material.updaterName': '修改人',
  'pages.material.material.updatedTime': '修改时间',
  'pages.material.material.approverName': '审核人',
  'pages.material.material.approvedTime': '审核时间',
  'pages.material.material.approveOpinion': '审核意见',
  'pages.material.material.createNew': '生成部件',
  'pages.material.material.reviewEBom': '预览E-BOM',
  'pages.material.material.EBomReview': 'E-BOM预览',
  'pages.material.material.EBomList': 'E-BOM列表',
  'pages.material.material.reviewMBom': '预览M-BOM',
  'pages.material.material.MBomList': 'M-BOM列表',
  'pages.material.material.MBomReview': 'M-BOM预览',
  'pages.material.material.componentInformation': '部件信息',
  'pages.material.material.componentAuditInformation': '部件审核信息',
  'pages.material.material.bomName': 'BOM名称',
  'pages.material.material.bomVersions': 'BOM版本',
  'pages.material.material.createStatus': '创建状态',
  'pages.material.document.list.field.rejectionReason': '驳回原因',
  'pages.material.document.list.field.auditOpinion': '审核意见',
  'pages.material.document.list.field.versionNo': '版本号',
  'pages.material.material.materialUseTypeName': '物料用途',
  'pages.material.material.materialUseTypeName1': '钣金类',
  'pages.material.material.materialUseTypeName2': '成套类',
  'pages.material.material.marketPrice': '面价(元)',
  'pages.material.material.model': '型号',
  'pages.material.material.cloudMaterialId': '公库编码',
  'pages.material.material.manufacturingTypeName': '制造方式',
  'pages.material.material.manufacturingTypeName1': '组装',
  'pages.material.material.manufacturingTypeName2': '制造',
  'pages.material.material.volume': '体积',
  'pages.material.material.modal.delete.hint.one': '请确认您要删除这个物料',
  'pages.material.material.modal.delete.hint.two': '删除该物料后，将无法恢复，是否确认？',
  'pages.material.material.unfoldLength': '展开长度',
  'pages.material.material.unfoldWidth': '展开宽度',
  'pages.material.material.deviceModel': '器件模型',
  'pages.material.material.materialPublicNo': '公库物料编码',
  'pages.material.material.materialPublicShortNo': '公库订货代码',
  'pages.material.material.push.kingdeeNo': '推送BOM到ERP',
  'pages.material.material.push.component.to.kingdeeNo': '推送子部件至ERP',
  'pages.material.material.push.mes': '推送至MES',
  'pages.material.material.push.to.kingdeeNo': '推送至ERP',

  'pages.material.material.push.kingdeeNo.state': '推送至ERP状态',
  'pages.material.material.push.mes.state': '推送至MES状态',
  'pages.material.material.inventory.info': '库存信息',
  'pages.material.material.batchManage': '启用批号',
  'pages.material.material.snManage': '启用序列号',
  'pages.material.material.kfPeriod': '启用保质期',

  'pages.material.material.quality.info': '质量信息',
  'pages.material.material.checkIncoming': '来料检验',
  'pages.material.material.checkProduct': '产品检验',
  'pages.material.material.checkStock': '库存检验',
  'pages.material.material.checkReturn': '退货检验',
  'pages.material.material.checkDelivery': '发货检验',
  'pages.material.material.checkReturnMtrl': '生产退料检验',
  'pages.material.material.checkSubRtnMtrl': '委外退料检验',

  'pages.material.material.copyMaterial': '复制物料',
  'pages.material.material.copyMaterial.hint': '复制物料时，是否同步复制关联文档？',
  'pages.material.material.copyMaterial.btn1': '同步并复制',
  'pages.material.material.copyMaterial.btn2': '仅复制物料信息',

  'pages.material.material.batch.operation': '批量编辑',
  'pages.material.material.next.step': '下一步',
  'pages.material.material.last.step': '上一步',

  'pages.material.material.modal.changeField': '修改字段',
  'pages.material.material.modal.changeValue': '修改后',
  'pages.material.material.modal.changeMaterial': '修改的物料',

  'pages.material.material.push.state0': '待推送',
  'pages.material.material.push.state1': '推送中',
  'pages.material.material.push.state2': '推送成功',
  'pages.material.material.push.state3': '推送失败',

  'pages.material.material.batch.audit': '批量审核',

  'pages.material.material.image.preview': '图片预览',

  'pages.material.material.batch.reverseReview': '批量反审核',

  'pages.material.material.existDeviceModel': 'DFM模型',
  'pages.material.material.existEBModel': 'EB模型',
  'pages.material.material.have.existDeviceModel': '有无DFM模型',
  'pages.material.material.have.existEBModel': '有无EB模型',
}

export default privateLibrary
